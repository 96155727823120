<template>
  <TransactionsComponent />
</template>


<script>

import TransactionsComponent from "@/components/transactions/transactionsComponent.vue";

export default {
  name: "StatisticView",
  components: {
    TransactionsComponent
  },
};
</script>